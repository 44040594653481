import { createListenerMiddleware, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export type AuthSliceState = {
  token?: string;
  fullName?: string;
  avatar?: string;
  roles?: string[];
};
const authDefaultState: AuthSliceState = {};

const getInitialState = (): AuthSliceState => {
  const authItemSerialized = localStorage.getItem("auth");
  const auth: AuthSliceState = authItemSerialized
    ? JSON.parse(authItemSerialized)
    : authDefaultState;
  return auth;
};

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    setCredentials: (state, action: PayloadAction<AuthSliceState>) => {
      state.token = action.payload.token;
      state.fullName = action.payload.fullName;
      state.avatar = action.payload.avatar;
      state.roles = action.payload.roles;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload;
    },
    signOut: (state) => {
      state.token = undefined;
      state.fullName = undefined;
      state.avatar = undefined;
      state.roles = undefined;
    },
  },
});

export const { setCredentials, setAvatar, signOut } = authSlice.actions;
export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isAnyOf(setCredentials, setAvatar, signOut),
  effect: async (action, listenerApi) => {
    if (action.type?.startsWith("auth/") || action.type?.startsWith("lk/edit/")) {
      localStorage.setItem("auth", JSON.stringify((listenerApi.getState() as RootState).auth));
    }
  },
});

export const authReducer = authSlice.reducer;
export const selectCurrentToken = (state: RootState) => state.auth.token;
export const selectCurrentUserFullName = (state: RootState) => state.auth.fullName;
export const selectCurrentUserAvatar = (state: RootState) => state.auth.avatar;
export const selectCurrentUserRoles = (state: RootState) => state.auth.roles;

