import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  block_dark_back_color,
  font_ligth_color,
  menu_back_color,
} from "../../constants/style";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentUserAvatar, selectCurrentUserRoles, signOut } from "../auth/auth-slice";
import { Role } from "../../constants/Roles";

let NAV_ITEMS: Array<NavItem> = [];

export default function () {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let roles = useAppSelector(selectCurrentUserRoles);
  let avatar = useAppSelector(selectCurrentUserAvatar);
  let isAdmin = roles && roles.includes(Role.ADMIN);
  let isUser = roles && roles.includes(Role.USER);

  NAV_ITEMS = [
    {
      id: "newsMenuItem",
      label: "Новости",
      href: "/publication/news",
    },
    {
      id: "articleMenuItem",
      label: "Статьи",
      href: "/publication/articles",
    },
  ];

  if (isAdmin) {
    NAV_ITEMS.push({
      id: "mderationMenuItem",
      label: "Модерация",
      href: "/moderation",
    });
    NAV_ITEMS.push({
      id: "usersMenuItem",
      label: "Пользователи",
      href: "/customers",
    });
  }
  return (
    <Box position="fixed" top="0" width="100%" zIndex={100}>
      <Flex
        bg={menu_back_color}
        color={font_ligth_color}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"transparent"}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            _hover={{
              background: menu_back_color,
            }}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }} id="menu_line">
          <Text fontFamily={"heading"} fontWeight={"bold"}>
            <Link
              _hover={{
                textDecoration: "underline",
              }}
              href="/#about"
              id="logo_img"
            >
              <Image w="16" h="8" src="/logoMenu.png"></Image>
            </Link>
          </Text>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Menu>
            <MenuButton
              data-testid='avatarMenuBtn'
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar
                size={"md"}
                src={
                  avatar
                }
              />
            </MenuButton>
            <MenuList
              bg={menu_back_color}
              color={font_ligth_color}
              borderColor={font_ligth_color}
            >
              {isUser ? (
                <>
                  <MenuItem
                    data-testid='lkMenuBtn'
                    onClick={() => {
                      window.location.href = "/lk";
                    }}
                    bg={menu_back_color}
                    color={font_ligth_color}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Личный кабинет
                  </MenuItem>
                  <MenuItem
                    data-testid='createPublBtn'
                    onClick={() => {
                      window.location.href = "/offer";
                    }}
                    bg={menu_back_color}
                    color={font_ligth_color}
                    _hover={{ textDecoration: "underline" }}
                  >
                    Предложить публикацию
                  </MenuItem>
                  <MenuDivider borderColor={font_ligth_color} borderWidth={"2px"} />
                </>
              ) : ''}
              <MenuItem
                data-testid='outMenuBtn'
                onClick={() => {
                  dispatch(signOut());
                  window.location.href = "/auth";
                }}
                bg={menu_back_color}
                color={font_ligth_color}
                _hover={{ textDecoration: "underline" }}
              >
                Выйти
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack >
      </Flex >

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box >
  );
}

const DesktopNav = () => {
  const linkColor = font_ligth_color;
  const linkHoverColor = font_ligth_color;
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <NavLink to={navItem.href ?? ""}
          onClick={() => {
            window.location.href = navItem.href ?? "#";
          }}>
          {({ isActive }) => {
            return (
              <Box
                key={navItem.label}
                bg={isActive ? block_dark_back_color : ""}
                borderRadius={"10px"}
              >
                <Link
                  id={navItem.id}
                  p={2}
                  fontSize={"lg"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "underline",
                    color: linkHoverColor,
                  }}
                >
                  {navItem.label}
                </Link>
              </Box>
            );
          }}
        </NavLink>
      ))}
    </Stack>
  );
};

const MobileNav = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack bg={menu_back_color} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          navItem={navItem}
          onClose={onClose}
        />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({
  navItem,
  onClose,
}: {
  navItem: NavItem;
  onClose: () => void;
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={navItem.children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={navItem.href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "underline",
          textDecorationColor: font_ligth_color,
        }}
      >
        <Text
          fontWeight={600}
          color={font_ligth_color}
          onClick={() => {
            onClose();
          }}
        >
          {navItem.label}
        </Text>
        {navItem.children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          align={"start"}
        >
          {navItem.children &&
            navItem.children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  id: string;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
