export enum PublicationState {
    MODERATION = "MODERATION",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED"
}

export function textPublicationState({ value }: { value: string | PublicationState }) {
    switch (value) {
        case PublicationState.MODERATION:
            return "Модерация";
        case PublicationState.REJECTED:
            return "Отклонено";
        case PublicationState.ACCEPTED:
            return "Принято";
    }
}


export function backColorPublicationState({ value }: { value: string | PublicationState }) {
    switch (value) {
        case PublicationState.MODERATION:
            return "gray.400";
        case PublicationState.REJECTED:
            return "red.400";
        case PublicationState.ACCEPTED:
            return "green.400";
    }
}
