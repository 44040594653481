import { Stack, Heading, Badge, Box, Text } from "@chakra-ui/react";
import { PublicationWithModeration } from "../../../api/types-response";
import { textPublicationState, backColorPublicationState } from "../../../constants/PublicationStates";
import { textPublicationTypes } from "../../../constants/PublicationTypes";
import { block_ligth_back_color } from "../../../constants/style";

export default function ({ data }: { data: PublicationWithModeration[] }) {
    return (
        <Stack spacing={6}>
            <Heading size={"md"}>
                {
                    data.length == 0
                        ? "Публикации отсутствуют"
                        : "Ваши публикации:"
                }
            </Heading>
            {data.map((el) =>
            (<Box
                data-testid='userPublication'
                w={"100%"}
                bg={block_ligth_back_color}
                borderRadius={"md"}
                p={4}
                boxShadow={"md"}
            >
                <Heading size={"sm"}>
                    {textPublicationTypes({ value: el.type })} #{el.id} <Badge bg={backColorPublicationState({ value: el.state })}>{textPublicationState({ value: el.state })}</Badge>
                </Heading>
                <Text isTruncated>
                    <b>Название:</b> {el.title}
                </Text>
                <Text>
                    <b>Комментарий администратора: </b>{el.comment ?? "Отсутствует"}
                </Text>
            </Box>)
            )}
        </Stack>
    );
}