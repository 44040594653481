
import { useNavigate } from "react-router-dom";
import { LoginRequest } from "../../api/types-request";
import { useForm } from "react-hook-form";
import { useSignInMutation } from "./auth-api-slice";
import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setCredentials } from "./auth-slice";
import {
  back_color,
  block_dark_back_color,
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  input_back_color,
} from "../../constants/style";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
} from "@chakra-ui/react";
import { LoginResponse } from "../../api/types-response";

export default function () {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginRequest>();
  const [signIn] = useSignInMutation();

  const [signInError, setSignInError] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const onSubmit = async (data: LoginRequest) => {
    setSignInError(null);
    try {
      await signIn(data)
        .unwrap()
        .then((payload: LoginResponse) => {
          console.log('fulfilled', payload)
          dispatch(
            setCredentials({
              token: payload.token,
              avatar: payload.avatar,
              roles: payload.roles,
              fullName: payload.fullName,
            })
          );
          window.location.href = "../publication/news";
        })
        .catch((error: TypeError) => {
          console.error('rejected', error);
          setSignInError(error.message);
        });
    } catch (err: any) {
      setSignInError(JSON.stringify(err));
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      color={font_color}
      bg={back_color}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Войдите в свой аккаунт
          </Heading>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box rounded={"lg"} bg={block_ligth_back_color} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Логин</FormLabel>
                <Input
                  id={"login"}
                  borderColor={block_dark_back_color}
                  focusBorderColor={font_color}
                  _hover={{ borderColor: font_color }}
                  _autofill={{
                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                  }}
                  bg={input_back_color}
                  type="text"
                  {...register("login", { required: "Обязательно для заполнения", })}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Пароль</FormLabel>
                <Input
                  id={"password"}
                  borderColor={block_dark_back_color}
                  focusBorderColor={font_color}
                  _hover={{ borderColor: font_color }}
                  _autofill={{
                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                  }}
                  bg={input_back_color}
                  type="password"
                  {...register("password", { required: "Обязательно для заполнения", })}
                />
              </FormControl>
              {signInError && <Text color={"red"} id="error">{signInError}</Text>}
              <Stack spacing={10}>
                <Button
                  colorScheme={"teal"}
                  color={font_ligth_color}
                  id="singIn"
                  type="submit"
                >
                  Войти
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}
