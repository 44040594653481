import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  block_dark_back_color,
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  input_back_color,
  menu_back_color,
} from "../../constants/style";
import { useForm } from "react-hook-form";
import { UserUpdateAuthUser } from "../../api/types-request";
import { useState } from "react";
import { useGetAuthUserQuery, useUpdateAuthUserMutation } from "../../api/api-slice";
import { AuthUser, FileBase64 } from "../../api/types-response";
import { handleFileRead } from "../../app/common";
import { setAvatar } from "../auth/auth-slice";
import { useAppDispatch } from "../../app/hooks";

export default function () {
  const { data: authUser, error: authUserError } = useGetAuthUserQuery(undefined);

  return authUserError ? (
    <>Ошибка загрузки данных</>
  ) : !authUser ? (
    <>
      <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner
          thickness='7px'
          speed='0.50s'
          emptyColor={font_ligth_color}
          color={menu_back_color}
          boxSize={130} />
      </div>
    </>
  ) : (
    <>
      <FormUpdate data={authUser} />
    </>
  )
}

function FormUpdate({ data }: { data: AuthUser }) {
  const [image, setImage] = useState<FileBase64>();
  const [imagePath, setImagePath] = useState<string>();
  const defaultValues: UserUpdateAuthUser = {
    lastName: data.last_name,
    firstName: data.first_name,
    patronymic: data.patronymic,
    birthday: data.birthday ? data.birthday.substring(0, 10) : data.birthday,
    experience: data.experience,
    education: data.education,
    avatar: undefined,
  };

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserUpdateAuthUser>({ defaultValues });
  const [signIn] = useUpdateAuthUserMutation();

  const onSubmit = async (data: UserUpdateAuthUser) => {
    try {
      if (image) {
        data.avatar = image;
      }
      await signIn(data)
        .unwrap()
        .then((payload: any) => {
          dispatch(
            setAvatar(payload.avatar)
          );
          window.location.href = "../lk";
        })
        .catch((error: TypeError) => {
          console.error('rejected', error);
        });
    } catch (err: any) {
      console.log(JSON.stringify(err))
    }
  };

  return (
    <Center mb="5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box rounded={"lg"} bg={block_ligth_back_color} boxShadow={"lg"} p={8}>
          <Heading size={"md"}>Личные данные для редактирования</Heading>
          <br />
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Фамилия</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="text"
                {...register("lastName", { required: "Обязательно для заполнения" })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Имя</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="text"
                {...register("firstName", { required: "Обязательно для заполнения" })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Отчество</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="text"
                {...register("patronymic", { required: "Обязательно для заполнения" })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>День рождения</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="date"
                {...register("birthday")}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Стаж</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="number"
                {...register("experience")}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Образование</FormLabel>
              <Input
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="text"
                {...register("education")}
              />
            </FormControl>
            <Input
              type="file"
              accept={"image/*"}
              id="fileSelector"
              value={imagePath}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                const fileData = await handleFileRead(e);
                if (!fileData) {
                  console.log("onChange: no fileData");
                  return;
                }
                setImagePath(e.target.value);
                setImage(fileData);
              }} />
            <Button
              onClick={(event: any) => { (document.getElementById("fileSelector") as HTMLInputElement).click(); }}
              bg={input_back_color}
              _hover={{ background: font_ligth_color, fontSize: '110%' }}>
              Выберите файл...
            </Button>
            <Stack spacing={10}>
              <Button
                colorScheme={"teal"}
                color={font_ligth_color}
                id="singIn"
                type="submit"
              >
                Изменить
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Center>
  );
}
function dispatch(arg0: { payload: string; type: "auth/setAvatar"; }) {
  throw new Error("Function not implemented.");
}

