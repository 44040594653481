import {
  Box,
  Heading,
  Button,
  Stack,
  Text,
  Image,
  Link,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  font_muted_color,
  menu_back_color,
} from "../../constants/style";
import { useGetPublicationsMMutation, useGetPublicationsQQuery } from "../../api/api-slice";
import { PagablePublicationShort, PublicationShort } from "../../api/types-response";
import { PublicationTypes } from "../../constants/PublicationTypes";
import { dateFormat, fileBase64ToSrc } from "../../constants/helpers";
import { useState, useEffect, useCallback } from "react";

export default function () {
  const { type } = useParams();
  const typeReq = (type ? type.toUpperCase() : "NEWS") as keyof typeof PublicationTypes;
  const { data, error } = useGetPublicationsQQuery(
    { offset: 0, limit: 10, type: PublicationTypes[typeReq] }
  );

  return error ? (
    <>Ошибка загрузки данных</>
  ) : !data ? (
    <>
      <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner
          thickness='7px'
          speed='0.50s'
          emptyColor={font_ligth_color}
          color={menu_back_color}
          boxSize={130} />
      </div>
    </>
  ) : (
    <Stack direction={"row"} spacing={8} mb={"5%"} minW='100%'>
      <Elements dat={data} />
    </Stack>
  );
}

function Elements({ dat }: { dat: PagablePublicationShort }) {
  const { type } = useParams();
  const typeReq = (type ? type.toUpperCase() : "NEWS") as keyof typeof PublicationTypes;
  const [data, setData] = useState<PublicationShort[]>(dat.data);
  const [count, setCount] = useState(dat.data.length);

  const [isSending, setIsSending] = useState(false);
  const [apply] = useGetPublicationsMMutation();
  const SendRequest = useCallback(async () => {
    // don't send again while we are sending
    if (isSending) return;
    // update state
    setIsSending(true);
    // send the actual request
    await apply({ offset: count, limit: 10, type: PublicationTypes[typeReq] })
      .unwrap()
      .then((payload: any) => {
        setData(data.concat(payload.data));
        setCount(count + payload.data.length);
      })
      .catch((error: TypeError) => {
        console.error('rejected', error);
      });
    // once the request is sent, update state again
    setIsSending(false);
  }, [isSending]); // update the callback if the state changes

  return (
    <>
      <Stack spacing={6} minW='100%'>
        {data.map((el: PublicationShort) => (
          <Box
            data-testid={type + '_oneItem'}
            w={"full"}
            bg={block_ligth_back_color}
            boxShadow={"md"}
            rounded={"md"}
            p={6}
            overflow={"hidden"}
          >
            <Stack spacing={4}>
              <Stack direction={"row"} spacing={4} align={"center"}>
                <Image
                  boxSize={10}
                  borderRadius={"25%"}
                  src={
                    el.avatar ? el.avatar : "../../../../photo.png"
                  }
                />
                <Text color={font_color} fontWeight={600}>
                  {el.authorName}
                </Text>
                <Text color={font_muted_color} data-testid='publicationCreatedDate'>
                  {dateFormat(el.created)}
                </Text>
              </Stack>
              <Link href={`/publication/${type}/${el.id}`}>
                <Heading
                  color={font_color}
                  fontSize={"2xl"}
                  fontFamily={"body"}
                  textAlign={"justify"}
                >
                  {el.title}
                </Heading>
              </Link>
              <Text noOfLines={5} color={font_muted_color} textAlign={"justify"}>
                {el.content}
              </Text>
              <Button
                onClick={() => {
                  window.location.href = `/publication/${type}/${el.id}`;
                }}
                colorScheme={"teal"}
                color={font_ligth_color}
              >
                Читать дальше...
              </Button>
            </Stack>
          </Box>
        ))}
        {count < dat.total ? (
          <Center>
            <Button
              onClick={SendRequest}
              color={font_muted_color}
              variant='link'
              colorScheme='teal'
              w='130px'
              minW='130px'
            >
              <Heading as={"h3"} fontSize={"xl"}>Показать ещё...</Heading>
            </Button>
          </Center>
        ) : ''}
      </Stack>
    </>
  );
}