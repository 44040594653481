import {
  Box,
  Heading,
  Button,
  Stack,
  Text,
  Image,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  font_muted_color,
  menu_back_color,
} from "../../constants/style";
import CustomInput from "../utils/CustomInput";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useGetPublicationQuery } from "../../api/api-slice";
import { Comment, PublicationFull } from "../../api/types-response";
import { dateFormat, fileBase64ToSrc } from "../../constants/helpers";
import Comments from "./components/Comments";

export default function () {
  const { id } = useParams();
  const { data, error } = useGetPublicationQuery(Number(id));

  return error ? (
    <>Ошибка загрузки данных</>
  ) : !data ? (
    <>
      <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner
          thickness='7px'
          speed='0.50s'
          emptyColor={font_ligth_color}
          color={menu_back_color}
          boxSize={130} />
      </div>
    </>
  ) : (
    <>
      <Stack direction={"row"} spacing={8} mb={"5%"} minW='100%'>
        <Element data={data} />
      </Stack>
    </>
  )
}

function Element({ data }: { data: PublicationFull }) {
  const { type } = useParams();

  return (
    <Stack spacing={8} mb={"5%"} minW='100%'>
      <Box
        id='publication'
        w={"full"}
        bg={block_ligth_back_color}
        boxShadow={"md"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Stack spacing={4}>
          <Stack direction={"row"} spacing={4} align={"center"}>
            <IconButton
              id='backBtn'
              bg={"transparent"}
              aria-label="Назад"
              icon={<IoIosArrowBack />}
              fontSize="35px"
              _hover={{ background: "transparent", fontSize: "25px" }}
              onClick={() => {
                window.location.href = `/publication/${type}`;
              }}
            />
            <Image
              boxSize={10}
              borderRadius={"25%"}
              src={
                data.publication.avatar ? data.publication.avatar : "../../../../photo.png"
              }
            />
            <Text color={font_color} fontWeight={600}>
              {data.publication.authorName}
            </Text>
            <Text color={font_muted_color} id='publicationCreatedDate'>
              {dateFormat(data.publication.created)}
            </Text>
          </Stack>
          <Heading
            color={font_color}
            fontSize={"2xl"}
            fontFamily={"body"}
            textAlign={"justify"}
          >
            {data.publication.title}
          </Heading>
          <Text color={font_muted_color} textAlign={"justify"}>
            {data.publication.content}
          </Text>
        </Stack>
      </Box>
      <Comments data={data.comments} />
    </Stack>
  );
}