import { Box, Spinner } from "@chakra-ui/react";
import { font_ligth_color, menu_back_color } from "../../constants/style";
import PersonalInfo from "./components/PersonalInfo";
import { useGetAuthUserQuery, useGetPublicationsWithModerationQuery } from "../../api/api-slice";
import UserPulications from "./components/UserPulications";

export default function () {
  const { data: authUser, error: authUserError } = useGetAuthUserQuery(undefined);
  const { data: publications, error: publicationsError } = useGetPublicationsWithModerationQuery(undefined);

  return authUserError || publicationsError ? (
    <>Ошибка загрузки данных</>
  ) : !authUser || !publications ? (
    <>
      <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner
          thickness='7px'
          speed='0.50s'
          emptyColor={font_ligth_color}
          color={menu_back_color}
          boxSize={130} />
      </div>
    </>
  ) : (
    <>
      <Box mb={"2%"}>
        <PersonalInfo data={authUser} />
        <UserPulications data={publications} />
      </Box>
    </>
  )
}