import {
  Box,
  Heading,
  Stack,
  Text,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  font_muted_color,
  menu_back_color,
} from "../../constants/style";
import { PublicationShort } from "../../api/types-response";
import { useGetModerationPublicationQuery } from "../../api/api-slice";
import { dateFormat, fileBase64ToSrc } from "../../constants/helpers";
import ModerationForm from "./components/ModerationForm";

export default function () {
  const { id } = useParams();
  const { data, error } = useGetModerationPublicationQuery(Number(id));

  return error ? (
    <>Ошибка загрузки данных</>
  ) : !data ? (
    <>
      <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner
          thickness='7px'
          speed='0.50s'
          emptyColor={font_ligth_color}
          color={menu_back_color}
          boxSize={130} />
      </div>
    </>
  ) : (
    <>
      <Stack direction={"row"} spacing={8} mb={"5%"} minW='100%'>
        <Element data={data} />
      </Stack>
    </>
  )
}

function Element({ data }: { data: PublicationShort }) {
  const navigate = useNavigate();
  return (
    <Stack spacing={8} mb={"5%"} minW='100%'>
      <Box
        id='moderationInfo'
        w={"full"}
        bg={block_ligth_back_color}
        boxShadow={"md"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <Stack spacing={4}>
          <Stack direction={"row"} spacing={4} align={"center"}>
            <Image
              boxSize={10}
              borderRadius={"25%"}
              src={
                data.avatar ? data.avatar : "../../../../photo.png"
              }
            />
            <Text color={font_color} fontWeight={600}>
              {data.authorName}
            </Text>
            <Text color={font_muted_color}>
              {dateFormat(data.created)}
            </Text>
          </Stack>
          <Heading
            color={font_color}
            fontSize={"2xl"}
            fontFamily={"body"}
            textAlign={"justify"}
          >
            {data.title}
          </Heading>
          <Text color={font_muted_color} textAlign={"justify"}>
            {data.content}
          </Text>
        </Stack>
      </Box>
      <ModerationForm />
    </Stack>
  );
}