import {
  Box,
  Button,
  Stack,
  InputGroup,
  InputRightElement,
  CloseButton,
  Spinner,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Text
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import {
  block_dark_back_color,
  font_color,
  font_ligth_color,
  input_back_color,
  input_placehlder_color,
  menu_back_color,
} from "../../constants/style";
import {
  useChangeBlockedUserMutation,
  useGetUsersQuery,
} from "../../api/api-slice";
import CreateCustomer from "./components/CreateCustomer";
import { useState } from "react";
import { User } from "../../api/types-response";
import UpdateCustomer from "./components/UpdateCustomer";
import { onlyDateFormat, dateFormat } from "../../constants/helpers";

export default function () {
  const { data, error } = useGetUsersQuery(undefined);
  return (
    <Stack direction={"row"} spacing={8} mb={"5%"} minW="100%">
      {error ? (
        <>Ошибка загрузки данных</>
      ) : !data ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spinner
              thickness="7px"
              speed="0.50s"
              emptyColor={font_ligth_color}
              color={menu_back_color}
              boxSize={130}
            />
          </div>
        </>
      ) : (
        <>
          <Customers data={data} />
        </>
      )}
    </Stack>
  );
}

export function generateRandomString({ length }: { length: number }) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

function Customers({ data }: { data: User[] }) {
  const {
    isOpen: isOpen_Create,
    onOpen: onOpen_Create,
    onClose: onClose_Create,
  } = useDisclosure();

  const [search, setSearch] = useState("");

  if (data) {
    data = data.filter((el) =>
      el.login.toLocaleLowerCase().includes(search)
      || el.email.toLocaleLowerCase().includes(search)
      || (el.last_name + ' ' + el.first_name + ' ' + el.patronymic).toLocaleLowerCase().includes(search));
  }

  const [apply] = useChangeBlockedUserMutation();
  const onApply = async (id: number) => {
    await apply(id)
      .unwrap()
      .then((payload: any) => {
        window.location.reload();
      })
      .catch((error: TypeError) => {
        console.error("rejected", error);
      });
  };
  const onError = async (data: any) => {
    console.log("onError", { data });
  };

  return (
    <>
      <Stack spacing={8} mb={"2%"} w="100%">
        <Box>
          <Stack direction={"row"} display={{ base: "none", sm: "flex" }}>
            <Button
              onClick={onOpen_Create}
              p={4}
              minW={"max-content"}
              colorScheme={"teal"}
              color={font_ligth_color}
            >
              Добавить нового пользователя
            </Button>
            <InputGroup>
              <Input
                id="filter"
                name="filter"
                borderColor={block_dark_back_color}
                focusBorderColor={font_color}
                _hover={{ borderColor: font_color }}
                _autofill={{
                  boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                }}
                bg={input_back_color}
                type="text"
                placeholder="Поиск по ФИО, логину и почте"
                _placeholder={{ color: input_placehlder_color }}
                onChange={(event: any) => {
                  setSearch(event.target.value.toLocaleLowerCase());
                }}
              />
              <InputRightElement>
                <CloseButton
                  id="closeFilter"
                  onClick={(event: any) => {
                    (
                      document.getElementById("filter") as HTMLInputElement
                    ).value = "";
                    setSearch("");
                  }}
                />
              </InputRightElement>
            </InputGroup>
          </Stack>
        </Box>
        <CreateCustomer
          props={{
            isOpen: isOpen_Create,
            onOpen: onOpen_Create,
            onClose: onClose_Create,
          }}
        />
        <TableContainer
          borderWidth={"2px"}
          borderColor={block_dark_back_color}
          borderRadius={"20px"}
          boxShadow={"md"}
          p={"20px"}
        >
          <Table variant="simple" color={font_color} layout="fixed">
            <Thead>
              <Tr>
                <Th borderColor={block_dark_back_color} color={font_color} w='10%'>
                  Логин
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='20%'>
                  Почта
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='25%'>
                  ФИО
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='12%' maxW={'12%'} sx={{ wordWrap: 'normal' }}>
                  Дата рождения
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='10%'>
                  Образование
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='5%'>
                  Стаж
                </Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='9%'></Th>
                <Th borderColor={block_dark_back_color} color={font_color} w='9%'></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((el) => (
                <Tr>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.login}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.email}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.last_name} {el.first_name} {el.patronymic}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.birthday ? el.birthday.substring(0, 10) : ""}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.education}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    {el.experience}
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    <UpdateCustomer data={el} />
                  </Td>
                  <Td
                    borderColor={
                      el.id == data[data.length - 1].id
                        ? "transparent"
                        : block_dark_back_color
                    }
                  >
                    <IconButton
                      data-testid={el.blocked ? "unblockBtn" : "blockBtn"}
                      colorScheme="red"
                      aria-label="block-user"
                      size="lg"
                      icon={el.blocked ? <UnlockIcon /> : <LockIcon />}
                      onClick={() => {
                        onApply(el.id);
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
}
