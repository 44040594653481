import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  IoAnalyticsSharp,
  IoLogoBitcoin,
  IoConstructOutline,
  IoCardOutline,
  IoChatbubbleEllipsesOutline,
} from "react-icons/io5";
import { ReactElement } from "react";
import { font_color, font_muted_color } from "../../../constants/style";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        p={2}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function () {
  return (
    <Box id="requirements">
      <Container maxW={"5xl"} py={12} pt={20}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading color={font_color}>Кто может стать резидентом?</Heading>
            <Text
              color={font_muted_color}
              fontSize={"lg"}
              textAlign={"justify"}
            >
              Юристы, адвокаты, эксперты, интересующиеся делами о ДТП, опытные
              или только начинающие практику по этой категории дел.
            </Text>
            <Text color={font_color} fontSize={"lg"} fontWeight={"medium"}>
              Требования:
            </Text>
            <Stack
              spacing={4}
              divider={<StackDivider borderColor={"#B0BFB8"} />}
            >
              <Feature
                icon={
                  <Icon
                    as={IoChatbubbleEllipsesOutline}
                    color={"yellow.700"}
                    w={5}
                    h={5}
                  />
                }
                iconBg={"yellow.300"}
                text={"Желание и готовность общаться и делиться практикой"}
              />
              <Feature
                icon={
                  <Icon as={IoAnalyticsSharp} color={"green.700"} w={5} h={5} />
                }
                iconBg={"green.300"}
                text={
                  "Стремление повышать уровень своего профессионального мастерства"
                }
              />
              <Feature
                icon={
                  <Icon
                    as={IoConstructOutline}
                    color={"purple.700"}
                    w={5}
                    h={5}
                  />
                }
                iconBg={"purple.300"}
                text={"Желание быть в сообществе профессионалов"}
              />
              <Feature
                icon={<Icon as={IoCardOutline} color={"red.700"} w={5} h={5} />}
                iconBg={"red.300"}
                text={"Регистрация на сайте Института Адвокатуры"}
              />
              <Feature
                icon={
                  <Icon as={IoLogoBitcoin} color={"blue.700"} w={5} h={5} />
                }
                iconBg={"blue.300"}
                text={"Оплата резидентства"}
              />
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
