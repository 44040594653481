import { Center, Stack, Heading, IconButton, Flex, List, ListItem, Image, Text, Box } from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { AuthUser } from "../../../api/types-response";
import { block_ligth_back_color, small_accent } from "../../../constants/style";
import { onlyDateFormat } from "../../../constants/helpers";

export default function ({ data }: { data: AuthUser }) {
    return (
        <Center py={6}>
            <Stack
                bg={block_ligth_back_color}
                p={"20px"}
                borderRadius={"10px"}
                boxShadow={"md"}
                id='personalInfo'
            >
                <Heading fontSize={"2xl"} textAlign={"center"}>
                    {data.last_name} {data.first_name} {data.patronymic} {"  "}
                    <IconButton
                        id='personalInfoEditBtn'
                        icon={<AiOutlineEdit />}
                        fontSize={"25px"}
                        aria-label="Редактировать личные данные"
                        variant={"unstyled"}
                        color={small_accent}
                        onClick={() => {
                            window.location.href = "../lk/edit";
                        }}
                    />
                </Heading>
                <Stack direction={{ base: "column", md: "row" }} padding={4}>
                    <Flex flex={1} bg="blue.200">
                        <Image
                            objectFit="cover"
                            boxSize="100%"
                            maxH={'300px'}
                            src={
                                data.avatar ?? "../../../../../photo.png"
                            }
                        />
                    </Flex>
                    <Stack
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        p={1}
                        pt={2}
                    >
                        <Box>
                            <List spacing={2}>
                                <ListItem>
                                    <Text>
                                        <b>Логин: </b>{data.login}
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    <Text>
                                        <b>Почта: </b>{data.email}
                                    </Text>
                                </ListItem>
                                <ListItem>
                                    {data.birthday
                                        ? (<><b>Дата рождения:</b> {onlyDateFormat(data.birthday)}</>)
                                        : (<b>Дата рождения не указана</b>)
                                    }
                                </ListItem>
                                <ListItem>
                                    {data.experience
                                        ? (<><b>Стаж: </b>{data.experience}</>)
                                        : (<b>Стаж не указан</b>)
                                    }
                                </ListItem>
                                <ListItem>
                                    {data.experience
                                        ? (<><b>Образование:</b> {data.education}</>)
                                        : (<b>Образование не указано</b>)
                                    }
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
    );
}
