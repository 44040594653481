import { Box, ChakraProvider } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { back_color } from "../../constants/style";
import Footer from "../landing/components/Footer";
import SubMenu from "./SubMenu";

export default function SubLayout() {
  return (
    <ChakraProvider>
      <Box h={"100%"}>
        <SubMenu />
        <Box px={"5%"} pt={20} bg={back_color} minH={"calc(100vh - 200px)"}>
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </ChakraProvider>
  );
}
