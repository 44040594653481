export function ErrorFallback({ error }: { error: Error }) {
    console.log("ErrorFallback error", error);
    return (
        <div role="alert">
            <p>App error:</p>
            <pre style={{ fontSize: "15px", fontFamily: "Iosevka, monospace" }}>{error.message}</pre>
            <p>Stack:</p>
            <pre style={{ fontSize: "15px", fontFamily: "Iosevka, monospace" }}>{error.stack}</pre>
        </div>
    );
}
