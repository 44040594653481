import { FileBase64 } from "../api/types-response";

export const handleFileRead = async (
    event: React.ChangeEvent<HTMLInputElement>
): Promise<FileBase64 | undefined> => {
    try {
        const file = event.target.files?.[0];
        if (!file) {
            console.log("error reading file");
            return undefined;
        }
        const mime = file.type;
        if (!mime.startsWith("image/")) {
            return undefined;
        }
        const dataFull = await convertBase64(file);
        const data = dataFull.split("base64,")[1];
        console.log("handleFileRead", { mime, data });
        return { mime, data };
    } catch (error) {
        console.log("error uploading file", { error });
        return undefined;
    }
};

export const convertBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            if (typeof fileReader.result !== "string") {
                reject("Unexpected non-string result");
                return;
            }
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};