import {
    Box,
    Heading,
    Button,
    Stack,
    Text,
    Image,
    Divider,
    Spinner,
    FormControl,
    FormLabel,
    Input,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useCreateCommentMutation } from "../../../api/api-slice";
import { Comment } from "../../../api/types-response";
import { fileBase64ToSrc, dateFormat } from "../../../constants/helpers";
import { block_ligth_back_color, font_muted_color, font_ligth_color, block_dark_back_color, font_color, input_back_color } from "../../../constants/style";
import CustomInput from "../../utils/CustomInput";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

type NewCommentFormType = {
    comment: string;
};

export default function ({ data }: { data: Comment[] }) {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<NewCommentFormType>();
    const { id } = useParams();

    const [apply] = useCreateCommentMutation();
    const onApply = async (data: NewCommentFormType) => {
        try {
            await apply({ publicationId: Number(id), comment: data.comment });
            window.location.reload();
        } catch (err) {
            console.log("Произошла ошибка");
        }
    };
    const onError = async (data: any) => {
        console.log("onError", { data });
    };
    return (
        <Box
            id='comments'
            w={"full"}
            bg={block_ligth_back_color}
            boxShadow={"md"}
            rounded={"md"}
            p={6}
            overflow={"hidden"}
        >
            {data.length == 0 &&
                <Text color={font_muted_color} textAlign={"justify"} mb={3} w="100%">
                    Ваш комментарий будет первым!
                </Text>
            }
            {
                data.length > 0 && data.map((el: Comment) => (
                    <Stack spacing={4}>
                        <Stack direction={"row"} spacing={6} align={"center"}>
                            <Image
                                boxSize={10}
                                borderRadius={"25%"}
                                src={
                                    el.avatar ? el.avatar : "../../../../photo.png"
                                }
                            />
                            <Text fontWeight={600}>{el.authorName}</Text>
                            <Text color={font_muted_color} data-testid='commentCreateDate'>
                                {dateFormat(el.created)}
                            </Text>
                        </Stack>
                        <Text>{el.comment}</Text>
                        <Divider />
                    </Stack>
                ))
            }
            <form onSubmit={handleSubmit(onApply, onError)}>
                <Stack direction={"row"}>
                    <FormControl>
                        <Input
                            id={"comment"}
                            borderColor={block_dark_back_color}
                            focusBorderColor={font_color}
                            _hover={{ borderColor: font_color }}
                            _autofill={{
                                boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                            }}
                            bg={input_back_color}
                            type="text"
                            autoComplete="off"
                            placeholder="Введите комментарий..."
                            {...register("comment", { required: "Обязательно для заполнения", })}
                        />
                    </FormControl>
                    <Button colorScheme={"teal"} color={font_ligth_color} type='submit'>
                        Отправить
                    </Button>
                </Stack>
            </form>
        </Box >
    );
}
