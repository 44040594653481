import { ReactNode } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  block_dark_back_color,
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  font_muted_color,
  small_accent,
} from "../../../constants/style";

const title = "Свяжитесь с администратором";
const description =
  "Для получения инструкции вступления в клуб необходимо связаться с администратором по почте example@mail.ru и указать интересующий Вас тариф";
const status = "warning";
const duration = 9000;

export default function () {
  const toast = useToast();
  return (
    <Box id="pricing" color={font_color}>
      <Box pt={20}>
        <VStack spacing={2} textAlign="center" mb="2%">
          <Heading as="h2" fontSize="4xl">
            Стоимость и порядок оплаты клубного взноса
          </Heading>
        </VStack>
        <Stack
          direction={{ base: "column", md: "row" }}
          textAlign="center"
          justify="center"
          spacing={{ base: 4, lg: 10 }}
          py={10}
        >
          <Box
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={"#F2F7F7"}
            borderRadius={"xl"}
            my={5}
            id="price-wrapper-left"
          >
            <Box
              py={4}
              px={12}
              bg={block_dark_back_color}
              borderTopRadius={"xl"}
            >
              <Text fontWeight="500" fontSize="2xl">
                Месячный тариф
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  ₽
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  2500
                </Text>
                <Text fontSize="3xl" color={font_muted_color}>
                  /
                </Text>
                <Text fontSize="3xl" color={font_muted_color}>
                  месяц
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={block_ligth_back_color}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color={small_accent} />
                  Доступ к базе знаний
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color={small_accent} />
                  Эфиры
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color={small_accent} />
                  Закрытый телеграм-канал
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color={small_accent} />
                  Очные встречи
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color={small_accent} />
                  Специальные предложения
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button
                  w="full"
                  colorScheme="teal"
                  variant="outline"
                  onClick={() =>
                    toast({
                      title: title,
                      description: description,
                      status: status,
                      duration: duration,
                      isClosable: true,
                    })
                  }
                  id="become-resident-left"
                >
                  Стать резидентом
                </Button>
              </Box>
            </VStack>
          </Box>

          <Box
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={"#F2F7F7"}
            borderRadius={"xl"}
            my={5}
            id="price-wrapper-center"
          >
            <Box position="relative" borderTopRadius={"xl"} id="fsfds">
              <Box
                position="absolute"
                top="-16px"
                left="50%"
                style={{ transform: "translate(-50%)" }}
              >
                <Text
                  textTransform="uppercase"
                  bg={small_accent}
                  px={3}
                  py={1}
                  color={font_ligth_color}
                  fontSize="sm"
                  fontWeight="600"
                  rounded="xl"
                >
                  Выгодный
                </Text>
              </Box>
              <Box
                py={4}
                px={12}
                color={font_color}
                bg={block_dark_back_color}
                borderTopRadius={"xl"}
              >
                <Text fontWeight="500" fontSize="2xl">
                  Годовой абонемент
                </Text>
                <HStack justifyContent="center">
                  <Text fontSize="3xl" fontWeight="600">
                    ₽
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    1670
                  </Text>
                  <Text fontSize="3xl" color={font_muted_color}>
                    /
                  </Text>
                  <Text fontSize="3xl" color={font_muted_color}>
                    месяц
                  </Text>
                </HStack>
              </Box>
              <VStack
                bg={block_ligth_back_color}
                py={4}
                borderBottomRadius={"xl"}
              >
                <List spacing={3} textAlign="start" px={12}>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Доступ к базе знаний
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Эфиры
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Закрытый телеграм-канал
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Очные встречи
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Специальные предложения
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaCheckCircle} color={small_accent} />
                    Экономия 20 000 ₽ за год
                  </ListItem>
                </List>
                <Box w="80%" pt={7}>
                  <Button
                    w="full"
                    colorScheme="teal"
                    color={"#E8DED4"}
                    onClick={() =>
                      toast({
                        title: title,
                        description: description,
                        status: status,
                        duration: duration,
                        isClosable: true,
                      })
                    }
                    id="become-resident-center"
                  >
                    Стать резидентом
                  </Button>
                </Box>
              </VStack>
            </Box>
          </Box>

          <Box
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: "center", lg: "flex-start" }}
            borderColor={"#F2F7F7"}
            borderRadius={"xl"}
            my={5}
            id="price-wrapper-right"
          >
            <Box maxW={"md"} minW={"3xs"}>
              <Box
                py={4}
                bg={block_dark_back_color}
                borderTopRadius={"xl"}
                color={font_color}
              >
                <Text fontWeight="500" fontSize="2xl">
                  Порядок оплаты
                </Text>
              </Box>
              <VStack
                bg={block_ligth_back_color}
                py={4}
                borderBottomRadius={"xl"}
              >
                <List spacing={3} textAlign="justify" px={6}>
                  <ListItem>
                    <ListIcon as={ChevronRightIcon} color={small_accent} />
                    Вступление и взнос с 7 по 25 число месяца
                  </ListItem>
                  <ListItem>
                    <ListIcon as={ChevronRightIcon} color={small_accent} />
                    Резидентство начинается по факту оплаты
                  </ListItem>
                  <ListItem>
                    <ListIcon as={ChevronRightIcon} color={small_accent} />
                    Стоимость неизменна в течение года для всех, кто вступил до
                    апреля 2023 года (при условии непрерывного участия в клубе)
                  </ListItem>
                </List>
              </VStack>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
