import { Box, ChakraProvider } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { back_color } from "../../constants/style";

export default function Layout() {
  return (
    <ChakraProvider>
      <Box bg={back_color}>
        <Outlet />
      </Box>
    </ChakraProvider>
  );
}
