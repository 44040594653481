import {
    Button,
    FormControl,
    FormLabel,
    Icon,
    InputGroup,
    InputRightElement,
    UseDisclosureProps,
} from "@chakra-ui/react";
import {
    Input,
} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import {
    block_dark_back_color,
    block_ligth_back_color,
    font_color,
    font_ligth_color,
    good_btn_color,
    good_btn_hover_color,
    input_back_color,
    input_placehlder_color,
    menu_back_color,
} from "../../../constants/style";
import { useCreateUserMutation } from "../../../api/api-slice";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generateRandomString } from "../Customers";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

type NewUserFormType = {
    login: string;
    password: string;
    password2: string;
    last_name: string;
    first_name: string;
    patronymic: string;
    email: string;
};

export default function ({ props }: { props: UseDisclosureProps }) {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<NewUserFormType>();

    const [apply] = useCreateUserMutation();
    const onApply = async (formData: NewUserFormType) => {
        if (formData.password != formData.password2) {
            return;
        }
        await apply(formData)
            .unwrap()
            .then((payload: any) => {
                window.location.reload();
            })
            .catch((error: TypeError) => {
                console.error('rejected', error);
            });
    };
    const onError = async (data: any) => {
        console.log("onError", { data });
    };

    const [showPassword, setShowPassword] = useState(false);
    const handlePasswordVisibility = () => setShowPassword(!showPassword);

    const onCloseEvent = () => {
        reset();
        if (props && props.onClose) {
            props.onClose();
        }
    }

    return (
        <Modal closeOnOverlayClick={false} isOpen={props.isOpen!} onClose={onCloseEvent}>
            <ModalOverlay />
            <ModalContent bg={block_ligth_back_color} color={font_color}>
                <form onSubmit={handleSubmit(onApply, onError)}>
                    <ModalHeader>Добавление пользователя</ModalHeader>
                    <ModalCloseButton data-testid='modalCloseBtn' />
                    <ModalBody pb={6}>
                        <FormControl mt={4}>
                            <FormLabel>Логин</FormLabel>
                            <Input
                                {...register("login", { required: "Обязательно для заполнения" })}
                                borderColor={block_dark_back_color}
                                focusBorderColor={font_color}
                                _hover={{ borderColor: font_color }}
                                _autofill={{
                                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                }}
                                bg={input_back_color}
                                type="text"
                                placeholder="user_123"
                                _placeholder={{ color: input_placehlder_color }}
                                autoComplete="disabled"
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Пароль</FormLabel>
                            <InputGroup>
                                <Input
                                    {...register("password", { required: "Обязательно для заполнения" })}
                                    borderColor={block_dark_back_color}
                                    focusBorderColor={font_color}
                                    _hover={{ borderColor: font_color }}
                                    _autofill={{
                                        boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                    }}
                                    bg={input_back_color}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="*******"
                                    _placeholder={{ color: input_placehlder_color }}
                                    autoComplete="new-password"
                                />
                                <InputRightElement width="3rem">
                                    <Button h="1.5rem"
                                        size="sm"
                                        onClick={handlePasswordVisibility}
                                        backgroundColor={input_back_color}
                                        _hover={{ backgroundColor: input_back_color }}
                                    >
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Повторите пароль</FormLabel>
                            <InputGroup>
                                <Input
                                    {...register("password2", { required: "Обязательно для заполнения" })}
                                    borderColor={block_dark_back_color}
                                    focusBorderColor={font_color}
                                    _hover={{ borderColor: font_color }}
                                    _autofill={{
                                        boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                    }}
                                    bg={input_back_color}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="*******"
                                    _placeholder={{ color: input_placehlder_color }}
                                    autoComplete="new-password"
                                />
                                <InputRightElement width="3rem">
                                    <Button h="1.5rem"
                                        size="sm"
                                        onClick={handlePasswordVisibility}
                                        backgroundColor={input_back_color}
                                        _hover={{ backgroundColor: input_back_color }}
                                    >
                                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>ФИО</FormLabel>
                            <Input
                                {...register("last_name", { required: "Обязательно для заполнения" })}
                                borderColor={block_dark_back_color}
                                focusBorderColor={font_color}
                                _hover={{ borderColor: font_color }}
                                _autofill={{
                                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                }}
                                bg={input_back_color}
                                type="text"
                                placeholder="Фамилия"
                                _placeholder={{ color: input_placehlder_color }}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input
                                {...register("first_name", { required: "Обязательно для заполнения" })}
                                borderColor={block_dark_back_color}
                                focusBorderColor={font_color}
                                _hover={{ borderColor: font_color }}
                                _autofill={{
                                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                }}
                                bg={input_back_color}
                                type="text"
                                placeholder="Имя"
                                _placeholder={{ color: input_placehlder_color }}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <Input
                                {...register("patronymic", { required: "Обязательно для заполнения" })}
                                borderColor={block_dark_back_color}
                                focusBorderColor={font_color}
                                _hover={{ borderColor: font_color }}
                                _autofill={{
                                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                }}
                                bg={input_back_color}
                                type="text"
                                placeholder="Отчество"
                                _placeholder={{ color: input_placehlder_color }}
                            />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Почта</FormLabel>
                            <Input
                                {...register("email", { required: "Обязательно для заполнения" })}
                                borderColor={block_dark_back_color}
                                focusBorderColor={font_color}
                                _hover={{ borderColor: font_color }}
                                _autofill={{
                                    boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                                }}
                                bg={input_back_color}
                                type="email"
                                placeholder="example@mail.ru"
                                _placeholder={{ color: input_placehlder_color }}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="teal"
                            mr={"auto"}
                            color={font_ligth_color}
                            onClick={() => {
                                const password = generateRandomString({ length: 8 });
                                (document.getElementsByName("password")[0] as HTMLInputElement).value = password;
                                (document.getElementsByName("password2")[0] as HTMLInputElement).value = password;
                                setValue('password', password);
                                setValue('password2', password);
                            }}>
                            Генерация
                        </Button>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            bg={good_btn_color}
                            _hover={{ background: good_btn_hover_color }}
                            color={font_ligth_color}
                            type='submit'
                        >
                            Добавить
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal >
    );
}