import {
  Box,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Stack,
  InputGroup,
  InputRightElement,
  CloseButton,
  Wrap,
  WrapItem,
  Avatar,
  Center,
  useColorModeValue,
  Text,
  Image,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Link,
  Input,
  Divider,
  Textarea,
  Select,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { EditIcon, ExternalLinkIcon, LockIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import {
  block_ligth_back_color,
  font_color,
  font_ligth_color,
  font_muted_color,
  input_placehlder_color,
} from "../../constants/style";
import {
  PublicationTypes
} from "../../constants/PublicationTypes";
import { useForm } from "react-hook-form";
import { useCreatePublicationMutation } from "../../api/api-slice";
import { useNavigate } from "react-router-dom";
import { PublicationShort } from "../../api/types-response";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentUserAvatar, selectCurrentUserFullName } from "../auth/auth-slice";

type NewPublicationFormType = {
  title: string;
  text: string;
  type: string;
};

export default function () {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NewPublicationFormType>();

  const [apply] = useCreatePublicationMutation();
  const onApply = async (formData: NewPublicationFormType) => {
    try {
      const data: any = await apply(formData);
      console.log(data);
      const res: PublicationShort = (data.data as PublicationShort);
      console.log(res);
      window.location.href = `../../publication/${formData.type.toLowerCase()}/${res.id}`;
    } catch (err) {
      console.log("Произошла ошибка");
    }
  };
  const onError = async (data: any) => {
    console.log("onError", { data });
  };

  let avatar = useAppSelector(selectCurrentUserAvatar);

  return (
    <Stack spacing={8} mb={"5%"}>
      <Box
        w={"full"}
        bg={block_ligth_back_color}
        boxShadow={"md"}
        rounded={"md"}
        p={6}
        overflow={"hidden"}
      >
        <form onSubmit={handleSubmit(onApply, onError)}>
          <Stack spacing={4}>
            <Select
              bg="teal"
              borderColor="teal"
              color={font_ligth_color}
              w={"max-content"}
              fontWeight={"bold"}
              {...register("type")}
            >
              <option value={PublicationTypes.NEWS} style={{ background: "teal" }} color={font_color}>
                Новость
              </option>
              <option value={PublicationTypes.ARTICLES} style={{ background: "teal" }} color={font_color}>
                Статья
              </option>
            </Select>
            <Stack direction={"row"} spacing={4} align={"center"}>
              <Avatar
                boxSize={10}
                borderRadius={"25%"}
                src={
                  avatar ?? "../../../../../photo.png"
                }
              />
              <Text color={font_color} fontWeight={600}>
                {useAppSelector(selectCurrentUserFullName) ?? "Ваши ФИО не заданы"}
              </Text>
            </Stack>
            <Input
              {...register("title", { required: "Обязательно для заполнения" })}
              variant="unstyled"
              placeholder="Заголовок"
              fontWeight={"bold"}
              fontSize={"1.5em"}
              color={input_placehlder_color}
              _placeholder={{ color: input_placehlder_color }}
            />
            <Textarea
              {...register("text", { required: "Обязательно для заполнения" })}
              minH={"40vh"}
              variant="unstyled"
              placeholder="Содержание"
              fontSize={"1.2em"}
              color={input_placehlder_color}
              _placeholder={{ color: input_placehlder_color }}
            />
            <Button colorScheme={"teal"} color={font_ligth_color} type='submit'>
              Отправить
            </Button>
          </Stack>
        </form>
      </Box>
    </Stack >
  );
}
