import {
  Stack,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  block_dark_back_color,
  font_color,
  font_ligth_color,
  menu_back_color,
} from "../../constants/style";
import { useGetModerationPublicationsQuery } from "../../api/api-slice";
import { PublicationModeration } from "../../api/types-response";

export default function () {
  const { data, error } = useGetModerationPublicationsQuery(undefined);
  return (
    <Stack direction={"row"} spacing={8} mb={"5%"} minW='100%'>
      {error ? (
        <>Ошибка загрузки данных</>
      ) : !data ? (
        <>
          <div style={{ position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <Spinner
              thickness='7px'
              speed='0.50s'
              emptyColor={font_ligth_color}
              color={menu_back_color}
              boxSize={130} />
          </div>
        </>
      ) : (
        <>
          <Stack spacing={8} mb={"2%"} w="100%">
            {data.length == 0 ?
              <Text align={'center'} fontSize={'2xl'}>
                Отсутствуют публикации для модерации
              </Text>
              :
              <TableContainer
                borderWidth={"2px"}
                borderColor={block_dark_back_color}
                borderRadius={"20px"}
                boxShadow={"md"}
                p={"20px"}
              >
                <Table variant="simple" color={font_color}>
                  <Thead>
                    <Tr>
                      <Th borderColor={block_dark_back_color} color={font_color}>
                        Номер
                      </Th>
                      <Th borderColor={block_dark_back_color} color={font_color}>
                        Тип
                      </Th>
                      <Th borderColor={block_dark_back_color} color={font_color}>
                        Пользователь
                      </Th>
                      <Th
                        borderColor={block_dark_back_color}
                        maxW={"200px"}
                        color={font_color}
                      >
                        Название
                      </Th>
                      <Th borderColor={block_dark_back_color}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Moderation data={data} />
                  </Tbody>
                </Table>
              </TableContainer>
            }
          </Stack>
        </>
      )
      }
    </Stack>
  );
}

function Moderation({ data }: { data: PublicationModeration[] }) {
  const navigate = useNavigate();
  data = data.slice().sort((a, b) => (a.id > b.id) ? 1 : -1);
  return (
    <>
      {data.map((el) => (
        <Tr>
          <Td borderColor={(el.id == data[data.length - 1].id) ? "transparent" : block_dark_back_color}>#{el.id}</Td>
          <Td borderColor={(el.id == data[data.length - 1].id) ? "transparent" : block_dark_back_color}>{el.type}</Td>
          <Td borderColor={(el.id == data[data.length - 1].id) ? "transparent" : block_dark_back_color}>{el.author}</Td>
          <Td
            borderColor={(el.id == data[data.length - 1].id) ? "transparent" : block_dark_back_color}
            maxW={"200px"}
            isTruncated
          >
            {el.title}
          </Td>
          <Td borderColor={(el.id == data[data.length - 1].id) ? "transparent" : block_dark_back_color}>
            <IconButton
              colorScheme="teal"
              aria-label="moderateElement"
              size="lg"
              icon={<EditIcon />}
              color={font_ligth_color}
              onClick={() => {
                window.location.href = `../moderation/${el.id}`;
              }}
            />
          </Td>
        </Tr>
      ))}
    </>
  );
}
