
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
    block_dark_back_color,
    font_color,
    font_ligth_color,
    input_back_color,
} from "../../../constants/style";
import {
    Input,
    Stack,
    Button,
    Image, Box, Text, Link
} from "@chakra-ui/react";
import { good_btn_color, good_btn_hover_color, bad_btn_color, bad_btn_hover_color } from "../../../constants/style";
import CustomInput from "../../utils/CustomInput";
import { ModerationRequest } from "../../../api/types-request";
import { useModerationMutation } from "../../../api/api-slice";

export default function () {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<ModerationRequest>();
    const [moderate] = useModerationMutation();

    const onSubmit = async (data: ModerationRequest) => {
        try {
            if (id) {
                await moderate({ id: Number(id), data })
                    .unwrap()
                    .then((payload: any) => {
                        window.location.href = "../moderation";
                    })
                    .catch((error: TypeError) => {
                        console.error('rejected', error);
                    });
            }
        } catch (err: any) {
            console.error('rejected', err);
        }
    };

    return (
        <Box mt='4 !important'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display={{ md: "flex" }} w='100%'>
                    <Box flexShrink={0} w={{ base: '100%', md: '70%' }}>
                        <Input
                            id={"comment"}
                            borderColor={block_dark_back_color}
                            focusBorderColor={font_color}
                            _hover={{ borderColor: font_color }}
                            _autofill={{
                                boxShadow: "0 0 0px 1000px " + input_back_color + " inset",
                            }}
                            bg={input_back_color}
                            type="text"
                            placeholder="Введите комментарий пользователю..."
                            {...register("comment", { required: "Обязательно для заполнения", })}
                        />
                    </Box>
                    <Box mt={{ base: 2, md: 0 }} ml={{ md: 3 }} w={{ base: '100%', md: '15%' }}>
                        <Button
                            bg={good_btn_color}
                            _hover={{ background: good_btn_hover_color }}
                            color={font_ligth_color}
                            type="submit"
                            w='100% '
                            onClick={() => { setValue("rejected", false); }}
                        >
                            Подтвердить
                        </Button>
                    </Box>
                    <Box mt={{ base: 2, md: 0 }} ml={{ md: 3 }} w={{ base: '100%', md: '15%' }}>
                        <Button
                            bg={bad_btn_color}
                            _hover={{ background: bad_btn_hover_color }}
                            color={font_ligth_color}
                            type="submit"
                            w='100% '
                            onClick={() => { setValue("rejected", true); }}
                        >
                            Отклонить
                        </Button>
                    </Box>
                </Box>
            </form>
        </Box>
    );
}
