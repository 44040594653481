import { logUrl, apiSlice } from "../../api/api-slice";
import { LoginRequest } from "../../api/types-request";
import { LoginResponse } from "../../api/types-response";

const extendedApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        signIn: build.mutation<LoginResponse, LoginRequest>({
            query: (data) => ({
                url: logUrl(`/auth/signIn`),
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useSignInMutation } = extendedApi;