export enum PublicationTypes {
    NEWS = "NEWS",
    ARTICLES = "ARTICLES",
}

export function textPublicationTypes({ value }: { value: string | PublicationTypes }) {
    switch (value) {
        case PublicationTypes.ARTICLES:
            return "Статья";
        case PublicationTypes.NEWS:
            return "Новость";
    }
}
