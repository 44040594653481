import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Landing from "./pages/landing/Landing";
import { Outlet, Route, useLocation } from "react-router-dom";
import Layout from "./pages/layout/Layout";
import Auth from "./pages/auth/Auth";
import Customers from "./pages/customers/Customers";
import SubLayout from "./pages/layout/SubLayout";
import Publication from "./pages/publications/Publication";
import OnePublication from "./pages/publications/OnePublication";
import Moderation from "./pages/moderation/Moderation";
import OneModeration from "./pages/moderation/OneModeration";
import PersonalCabinet from "./pages/personal-cabinet/PersonalCabinet";
import NewPublication from "./pages/publications/NewPublication";
import EditPersonalData from "./pages/personal-cabinet/EditPersonalData";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ErrorBoundary } from "react-error-boundary";
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ErrorFallback } from "./app/ErrorFallback";
import { selectCurrentUserRoles } from "./pages/auth/auth-slice";
import { useAppSelector } from "./app/hooks";
import { Role } from "./constants/Roles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const CheckAccess = ({ allowedRoles }: { allowedRoles: Role[] }) => {
  let roles = useAppSelector(selectCurrentUserRoles);
  let hasAccess = roles && roles.some(r => allowedRoles.flatMap(ar => ar.toString()).includes(r));
  const location = useLocation();

  return hasAccess ? (
    <Outlet />
  ) : roles ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Non-authorized. */}
      <Route path="/" element={<Layout />}>

        <Route path="/" element={<Landing />} />
        <Route path="/auth" element={<Auth />} />

        <Route path="/" element={<SubLayout />}>

          <Route element={<CheckAccess allowedRoles={[Role.ADMIN]} />}>
            <Route path="/customers" element={<Customers />} />
            <Route path="/moderation" element={<Moderation />} />
            <Route path="/moderation/:id" element={<OneModeration />} />
          </Route>

          <Route element={<CheckAccess allowedRoles={[Role.ADMIN, Role.USER]} />}>
            <Route path="/publication">
              <Route path=":type" element={<Publication />} />
              <Route path=":type/:id" element={<OnePublication />} />
            </Route>
          </Route>

          <Route element={<CheckAccess allowedRoles={[Role.USER]} />}>
            <Route path="/offer" element={<NewPublication />} />
            <Route path="/lk" element={<PersonalCabinet />} />
            <Route path="/lk/edit" element={<EditPersonalData />} />
          </Route>

        </Route>
      </Route>
    </>
  )
);

root.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ErrorBoundary>
  </StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
