import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { font_muted_color, small_accent } from "../../../constants/style";

const features = [
  {
    id: 0,
    title: "Профессионально расти",
    text: "Очные встречи, вебинары, эфиры и контакты помогут в этом",
  },
  {
    id: 1,
    title: "Делиться опытом",
    text: "Своим и приобретать опыт благодаря коллегам",
  },
  {
    id: 2,
    title: "Быть в курсе",
    text: "Изменений в правоприменительной практике и полезных инструментах защиты",
  },
  {
    id: 3,
    title: "Советоваться",
    text: "Более опытные коллеги всегда будут рады Вам помочь!",
  },
  {
    id: 4,
    title: "Находить партнёров",
    text: "Благодаря очным встречам и контактам",
  },
  {
    id: 5,
    title: "Наращивать репутационный капитал",
    text: "Опыт, опыт и ещё раз опыт!",
  },
  {
    id: 6,
    title: "При необходимости переадресовывать доверителей",
    text: "Вы всегда найдёте подходящих людей среди коллег",
  },
];

export default function () {
  return (
    <Box id="about">
      <Box p={4} pt={20}>
        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Heading fontSize={"3xl"}>#Клуб264</Heading>
          <Text color={font_muted_color} fontSize={"xl"}>
            Клуб264 – это клуб адвокатов и юристов по делам о ДТП
            <br />
            Цель – создание удобной профессиональной среды, в которой можно:
          </Text>
        </Stack>

        <Container maxW={"9xl"} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={"top"}>
                <Box color={small_accent} px={2}>
                  <Icon as={CheckIcon} />
                </Box>
                <VStack align={"start"}>
                  <Text fontWeight={600}>{feature.title}</Text>
                  <Text color={font_muted_color}>{feature.text}</Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    </Box>
  );
}
