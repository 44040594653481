export const back_color = "#e3c2b5";
export const menu_back_color = "#561b10";
export const block_dark_back_color = "#cc9966";
export const block_ligth_back_color = "#f1e1da";

export const font_color = "#2b0d08";
export const font_muted_color = "#41140c";
export const font_ligth_color = "#f1e1da";

export const simple_btn_color = "#006666";
export const simple_btn_hover_color = "#008080";
export const bad_btn_color = "#972e1c";
export const bad_btn_hover_color = "#ac3520";
export const good_btn_color = "#339966";
export const good_btn_hover_color = "#39ac73";
export const font_btn_color = "#E8DED4";

export const small_accent = "#d74228";
export const input_back_color = "#f8f0ed";
export const input_placehlder_color = "#a68059";