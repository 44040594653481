import { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";
import {
  font_color,
  font_ligth_color,
  menu_back_color,
} from "../../../constants/style";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function () {
  return (
    <Stack
      bg={menu_back_color}
      color={font_ligth_color}
      h={"200px"}
      minH={"200px"}
      spacing={5}
      py={"30px"}
      px={"40px"}
      id="footer"
    >
      <ListHeader>Контакты</ListHeader>
      <Link href={"#"}>example@mail.ru</Link>
      <Text>© 2023 Ваша компания. Все права защищены</Text>
    </Stack>
    // <Box bg={menu_back_color} color={font_ligth_color}>
    //   <Container
    //     as={Stack}
    //     maxW={"6xl"}
    //     py={10}
    //   >
    //     <SimpleGrid spacing={8}>
    //       {/* <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}> */}
    //       <Stack align={"flex-start"}>
    //         <ListHeader>Контакты</ListHeader>
    //         <Link href={"#"}>example@mail.ru</Link>
    //       </Stack>
    //     </SimpleGrid>
    //   </Container>

    //   <Box borderTopWidth={1} borderStyle={"solid"} borderColor={"transparent"}>
    //     <Container
    //       as={Stack}
    //       // maxW={"6xl"}
    //       py={4}
    //       direction={{ base: "column", md: "row" }}
    //       spacing={4}
    //       justify={{ md: "space-between" }}
    //       align={{ md: "center" }}
    //     >
    //       <Text>© 2023 Ваша компания. Все права защищены</Text>
    //       {/* <Stack direction={'row'} spacing={6}>
    //         <SocialButton label={'Twitter'} href={'#'}>
    //           <FaTwitter />
    //         </SocialButton>
    //         <SocialButton label={'YouTube'} href={'#'}>
    //           <FaYoutube />
    //         </SocialButton>
    //         <SocialButton label={'Instagram'} href={'#'}>
    //           <FaInstagram />
    //         </SocialButton>
    //       </Stack> */}
    //     </Container>
    //   </Box>
    // </Box>
  );
}
