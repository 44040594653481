import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
  Container,
} from "@chakra-ui/react";
import { FcAssistant, FcCamcorderPro, FcContacts } from "react-icons/fc";
import { FaTelegramPlane, FaHandshake } from "react-icons/fa";
import { RiKakaoTalkLine } from "react-icons/ri";
import { TbDiscount2 } from "react-icons/tb";
import {
  block_ligth_back_color,
  font_color,
  font_muted_color,
} from "../../../constants/style";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={block_ligth_back_color}
        mb={1}
      >
        {icon}
      </Flex>
      <Text color={font_color} fontWeight={600}>
        {title}
      </Text>
      <Text color={font_muted_color}>{text}</Text>
    </Stack>
  );
};

export default function () {
  return (
    <Box id="benefits">
      <Box p={4} pt={20}>
        <Stack
          spacing={4}
          as={Container}
          maxW={"3xl"}
          textAlign={"center"}
          mb="3%"
        >
          <Heading fontSize={"3xl"}>Что включает клубный пакет?</Heading>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            title={"Вебинары"}
            text={
              "Один вебинар в месяц по клубной теме с электронным сертификатом и присвоением часов повышения квалификации. Вебинары проводят эксперты, адвокаты и юристы – практики по делам о ДТП."
            }
          />
          <Feature
            icon={<Icon as={FaTelegramPlane} w={10} h={10} color="blue.300" />}
            title={"Закрытый Telegram-чат"}
            text={
              "Только для резидентов Клуба, курируемый адвокатами. В telegram-чате обсуждаются темы клубных вебинаров и очных встреч, проводятся опросы."
            }
          />
          <Feature
            icon={<Icon as={FcCamcorderPro} w={10} h={10} />}
            title={"Доступ к эфирам"}
            text={
              "Доступ к записям прошедших эфиров клуба и документам, которыми мы успели поделиться."
            }
          />
          <Feature
            icon={<Icon as={FaHandshake} w={10} h={10} color="teal.500" />}
            title={"Очные встречи"}
            text={
              "С мая 2023 года – очные встречи резидентов Клуба в разных форматах с обсуждением реальных кейсов, живыми знакомствами и общением, интересной программой, часть из которой выбираем сами в чате."
            }
          />
          <Feature
            icon={<Icon as={TbDiscount2} w={10} h={10} color="orange.500" />}
            title={"Специальные предложения"}
            text={
              "Скидки до 20% - для резидентов Клуба264 от Института Адвокатуры на другие его мероприятия и учебные программы. На какое мероприятие будет скидка – выбираете вы в Telegram-чате!"
            }
          />
          <Feature
            icon={<Icon as={RiKakaoTalkLine} w={10} h={10} color="green.500" />}
            title={"Свободное профессиональное общение"}
            text={
              "А также возможность посоветоваться с коллегами и получать обратную связь и комментарий от куратора - Аркадия Келарева"
            }
          />
          <Feature
            icon={<Icon as={FcContacts} w={10} h={10} />}
            title={"Контакты"}
            text={
              "Нужные контакты, полезные инструменты защиты по делам о ДТП, обмен документами по теме, судебная практика, экспертные комментарии и разборы от Аркадия Келарева и приглашенных адвокатов, юристов, специалистов."
            }
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
}
