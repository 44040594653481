import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import {
  block_dark_back_color,
  font_btn_color,
  font_color,
  font_ligth_color,
  menu_back_color,
  simple_btn_color,
  simple_btn_hover_color,
} from "../../../constants/style";
import { useNavigate } from "react-router-dom";
import { selectCurrentToken } from "../../auth/auth-slice";
import { useAppSelector } from "../../../app/hooks";

export default function () {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Box position="fixed" top="0" width="100%" zIndex={100}>
      <Flex
        bg={menu_back_color}
        color={font_ligth_color}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"transparent"}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
            _hover={{
              background: menu_back_color,
            }}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }} id="menu_line">
          <Text fontFamily={"heading"} fontWeight={"bold"}>
            <Link
              _hover={{
                textDecoration: "underline",
              }}
              href="/#about"
              id="logo_img"
            >
              <Image w="16" h="8" src="/logoMenu.png"></Image>
            </Link>
          </Text>

          <Flex display={{ base: "none", md: "flex" }} ml={5}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <AuthButton />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = font_ligth_color;
  const linkHoverColor = font_ligth_color;

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            id={navItem.id}
            p={2}
            href={navItem.href ?? "#"}
            fontSize={"lg"}
            fontWeight={500}
            color={linkColor}
            _hover={{
              textDecoration: "underline",
              color: linkHoverColor,
            }}
          >
            {navItem.label}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack bg={menu_back_color} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          navItem={navItem}
          onClose={onClose}
        />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({
  navItem,
  onClose,
}: {
  navItem: NavItem;
  onClose: () => void;
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={navItem.children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={navItem.href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "underline",
          textDecorationColor: font_ligth_color,
        }}
      >
        <Text
          fontWeight={600}
          color={font_ligth_color}
          onClick={() => {
            onClose();
          }}
        >
          {navItem.label}
        </Text>
        {navItem.children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          align={"start"}
        >
          {navItem.children &&
            navItem.children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const AuthButton = () => {
  const navigate = useNavigate();
  let token = useAppSelector(selectCurrentToken);
  return (
    <Button
      id={token ? 'newsMenuItem' : 'signInMenuItem'}
      variant={"solid"}
      bg={simple_btn_color}
      _hover={{
        bg: simple_btn_hover_color,
      }}
      color={font_ligth_color}
      py={4}
      aria-label={token ? 'Новости' : 'Вход в аккаунт'}
      onClick={() => {
        window.location.href = token ? '../publication/news' : '../auth';
      }}
    >
      {token ? 'Новости' : 'Вход'}
    </Button>
  );
};

interface NavItem {
  id: string;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    id: "benefitsMenuItem",
    label: "Преимущества",
    href: "#benefits",
  },
  {
    id: "leadersMenuItem",
    label: "Эксперты",
    href: "#leaders",
  },
  {
    id: "requirementsMenuItem",
    label: "Требования",
    href: "#requirements",
  },
  {
    id: "pricingMenuItem",
    label: "Стоимость",
    href: "#pricing",
  },
];
