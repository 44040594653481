import { Box } from "@chakra-ui/react";
import Requirements from "./components/Requirements";
import About from "./components/About";
import Benefits from "./components/Benefits";
import Leaders from "./components/Leaders";
import Menu from "./components/Menu";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";

export default function Landing() {
  return (
    <Box>
      <Menu />
      <Box px={"5%"}>
        <About />
        <Benefits />
        <Leaders />
        <Requirements />
        <Pricing />
      </Box>
      <Footer />
    </Box>
  );
}
